export const businessDocuments = [
    { name: "id_pin", placeholder: "Copy of Owner's ID/PIN", type: "file", required: true },
    { name: "bank_statements", placeholder: "Bank Statements (Last 12 Months)", type: "file", required: true },
    // { name: "contracts_invoices", placeholder: "Copies of Business Receipts/Invoices", type: "file", required: true },
    { name: "certificate_of_registration", placeholder: "Business Registration Certificate (if registered)", type: "file", required: false },
    { name: "business_license", placeholder: "Business License (if applicable)", type: "file", required: false },
    // { name: "pd_cheques", placeholder: "Post-Dated Cheques (if applicable)", type: "file", required: false },
    // { name: "lpo", placeholder: "Local Purchase Order (LPO)", type: "file", required: false },
    // { name: "business_visits", placeholder: "Business Visit Report", type: "file", required: false },
    // { name: "utility_bills", placeholder: "Recent Utility Bills (if business has premises)", type: "file", required: false },
    { name: "resolution_to_borrow", placeholder: "Resolution to Borrow (if a partnership or registered entity)", type: "file", required: false },
    { name: "financial_statements", placeholder: "Financial Statements (if available)", type: "file", required: false },
    { name: "company_description", placeholder: "Brief Business Description", type: "text", required: false },  
    { name: "logo", placeholder: "Business Logo", type: "file", required: false },  
    // { name: "business_plan", placeholder: "Business Plan (for startups or expansion loans)", type: "file", required: false },
    // { name: "collateral_documents", placeholder: "Collateral Documents (if loan requires security)", type: "file", required: false },
    // { name: "tax_compliance", placeholder: "Tax Compliance Certificate (if applicable)", type: "file", required: false },
    // { name: "credit_report", placeholder: "Credit Report (if required by lender)", type: "file", required: false }
];

  
  export const businessRecordsOptions = [
    { value: "County council Business licence", label: "County Council Business Licence" },
    { value: "Lease/tenancy agreement", label: "Lease/Tenancy Agreement" },
    { value: "Purchase and sales records", label: "Purchase and Sales Records" },
    { value: "Photos of stock", label: "Photos of Stock" },
    { value: "Business premises", label: "Business Premises" },
  ];
  
  
  export const companyFormFields = [
    { name: "id_pin", placeholder: "Copy of ID/PIN", type: "file", required: true },
    { name: "company_KRA_pin", placeholder: "Company KRA PIN", type: "file", required: true },
    { name: "cr_12", placeholder: "CR 12", type: "file", required: true },
    { name: "business_licence", placeholder: "Business Licence", type: "file", required: true },
    { name: "certificate_of_registration", placeholder: "Certificate of Registration", type: "file", required: true },
    { name: "articles_of_memorandum_of_association", placeholder: "Articles of Memorandum of Association", type: "file", required: true },
    { name: "resolution_to_borrow", placeholder: "Resolution to Borrow", type: "file", required: true },
    { name: "company_profile", placeholder: "Company Profile", type: "file", required: false },
    { name: "copies_of_contracts_invoices", placeholder: "Copies of Contracts/Invoices", type: "file", required: false },
    { name: "lpo", placeholder: "Local Purchase Order (LPO)", type: "file", required: false },
    { name: "logo", placeholder: "Business Logo", type: "file", required: false },  
    { name: "company_description", placeholder: "Company Description", type: "text", required: true }, 
  ];
  

  
 
  export const directorFields = [
    { name: "name", placeholder: "Director Name" , required: true},
    { name: "email", placeholder: "Director Email" , required: true},
    { name: "phone_number", placeholder: "Director Phone", required: true },
  ];
  