import React, { forwardRef } from "react";
import { Stepper, Step, StepButton } from "@mui/material";
import { LayoutStepperRoot } from "./LayoutStepperRoot";
import PropTypes from "prop-types";
import { useLayoutController } from "../../context";
import { LayoutStepperButtonRoot } from "./LayoutStepButtonRoot";

const LayoutStepper = forwardRef(
  ({ size, error, success, disabled, steps, completed, activeStep, handleStep, ...rest }, ref) => {
    const [controller] = useLayoutController();
    const { darkMode } = controller;
    return (
      <LayoutStepperRoot ownerState={{ size, error, success, disabled, darkMode }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <LayoutStepperButtonRoot color="inherit" onClick={() => handleStep(index)}>
                {label}
              </LayoutStepperButtonRoot>
            </Step>
          ))}
        </Stepper>
      </LayoutStepperRoot>
    );
  }
);

LayoutStepper.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  disabled: false,
  select: false,
  options: [],
};

LayoutStepper.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  completed: PropTypes.objectOf(PropTypes.bool).isRequired,
  activeStep: PropTypes.number.isRequired,
  handleStep: PropTypes.func.isRequired,
};

export default LayoutStepper;
