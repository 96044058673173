const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  down: (key) => `@media (max-width:${breakpoints.values[key]}px)`,
  up: (key) => `@media (min-width:${breakpoints.values[key]}px)`,
};

export default breakpoints;
