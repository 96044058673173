/* eslint-disable react/prop-types */
// Layout Dashboard 2 MUI components
import LayoutBox from "../../../components/LayoutBox";
import LayoutTypography from "../../../components/LayoutTypography";
import LayoutAvatar from "../../../components/LayoutAvatar";
import LayoutBadge from "../../../components/LayoutBadge";

// Images
import team2 from "../../../assets/images/team-2.jpg";
import team3 from "../../../assets/images/team-3.jpg";
import team4 from "../../../assets/images/team-4.jpg";

export function LoansTaken({ image, name, email }) {
  return (
    <LayoutBox display="flex" alignItems="center" px={1} py={0.5}>
      <LayoutBox mr={2}>
        <LayoutAvatar src={image} alt={name} size="sm" variant="rounded" />
      </LayoutBox>
      <LayoutBox display="flex" flexDirection="column">
        <LayoutTypography variant="button" fontWeight="medium">
          {name}
        </LayoutTypography>
        <LayoutTypography variant="caption" color="secondary">
          {email}
        </LayoutTypography>
      </LayoutBox>
    </LayoutBox>
  );
}

export function Function({ job, org }) {
  return (
    <LayoutBox display="flex" flexDirection="column">
      <LayoutTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </LayoutTypography>
      <LayoutTypography variant="caption" color="secondary">
        {org}
      </LayoutTypography>
    </LayoutBox>
  );
}

const LoansTakensTableData = {
  columns: [
    { name: "LoansTaken", align: "left" },
    { name: "loanProductName", align: "left" }, // New column for Loan Product Name
    { name: "loanInfo", align: "left" },
    { name: "status", align: "center" },
    { name: "startDate", align: "center" },
    { name: "action", align: "center" },
  ],

  rows: [
    {
      LoansTaken: <LoansTaken image={team2} name="John Michael" email="john@creative-tim.com" />,
      loanInfo: <Function job="QuickLogBook" org="50,000" />,
      loanProductName: (
        <LayoutTypography variant="caption" fontWeight="medium" color="text">
          Logbook Loan {/* You can replace this with dynamic data from the loan object */}
        </LayoutTypography>
      ),
      status: (
        <LayoutBadge variant="gradient" badgeContent="Active" color="success" size="xs" container />
      ),
      startDate: (
        <LayoutTypography variant="caption" color="secondary" fontWeight="medium">
          23/04/18
        </LayoutTypography>
      ),
      action: (
        <LayoutTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          View
        </LayoutTypography>
      ),
    }
  ],
};

export default LoansTakensTableData;
