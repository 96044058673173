import { Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";

const Notification = ({ message, severity = "success", open, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }} // Position at Top-Right
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

Notification.propTypes = {
  onClose:PropTypes.func.isRequired,
  severity:PropTypes.string,
  open:PropTypes.bool,
  message:PropTypes.string,
}

export default Notification;