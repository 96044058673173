import { CircularProgress, Grid } from "@mui/material";
import LayoutButton from "../../../../components/LayoutButton";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import LayoutFileInput from "../../../../components/LayoutInput/LayoutFileInput";
import LayoutInput from "../../../../components/LayoutInput";
import { useSelector } from "react-redux";

const LoanDetails = ({
  handleSaveInputs,
  savingInputs,
  setActiveStep,
  detailsData,
  selectedLoan,
}) => {
  const loading=useSelector((state)=>state.loans.loading)
 
  // Dynamic Yup validation schema
  const createValidationSchema = (requirements) => {
    const schema = {};
    if (requirements?.length > 0) {
      requirements.forEach((field) => {
        schema[field.id] = field.required
          ? Yup.string().required(`${field.name} is required`)
          : Yup.string();
      });
    }
    return Yup.object().shape(schema);
  };

  const initialValues = selectedLoan?.requirements?.length
    ? Object.fromEntries(selectedLoan.requirements.map((req) => [req.id, ""]))
    : {};

  const [formData, setFormData] = useState(initialValues);

  // useEffect(() => {
  //   console.log("DetailsData:", detailsData);
  // }, [detailsData]);

  const validationSchema = createValidationSchema(selectedLoan?.requirements);

  // const handleSubmit = (values) => {
  //   const fileEntries = Object.entries(values)
  //     .filter(([key, value]) => value instanceof File) // Only include files
  //     .reduce((acc, [key, file]) => {
  //       acc[key] = { file_name: key, file }; // Use key as file_name
  //       return acc;
  //     }, {});

  //   const updatedFormData = {
  //     ...detailsData,
  //     files: fileEntries, // Store files in expected format
  //   };

  //   console.log("Updated FormData:", updatedFormData); // Debugging log
  //   handleSaveInputs(updatedFormData);
  // };

  const handleSubmit = (values) => {
    const fileEntries = Object.entries(values)
      .filter(([key, value]) => value instanceof File) // Only include files
      .map(([key, file]) => ({
        file_name: key,
        file, // File object itself
      })); // Convert object into an array

    const updatedFormData = {
      ...detailsData,
      files: fileEntries, // Convert files object into an array
    };

    console.log("Updated FormData:", updatedFormData); // Debugging log
    handleSaveInputs(updatedFormData);
  };

  const handleBack = (event) => {
    console.log("event back ");
    event.preventDefault(); // Not necessary, but won't hurt
    // setErrors({}); // Clears Formik validation errors
    setActiveStep(0);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <h2>{selectedLoan?.title}</h2>
            <p>{selectedLoan?.summary}</p>
            <Grid container spacing={2}>
              {Array.isArray(selectedLoan?.requirements) ? (
                selectedLoan.requirements.map((req) => (
                  <Grid item xs={12} sm={6} key={req.id}>
                    {req.type === "text" ? (
                      <Field
                        name={req.id}
                        as={LayoutInput}
                        placeholder={req.placeholder || req.name}
                      />
                    ) : req.type === "file" ? (
                      <LayoutFileInput
                        name={req.id}
                        required={req.required}
                        placeholder={req.name}
                        handleChange={(event) => setFieldValue(req.id, event.target.files[0])}
                      />
                    ) : null}
                    {errors[req.id] && touched[req.id] && (
                      <p style={{ color: "red" }}>{errors[req.id]}</p>
                    )}
                  </Grid>
                ))
              ) : (
                <p>No requirements found for this loan.</p>
              )}
            </Grid>

            <Grid item xs={5} className="mt-3">
              <LayoutButton onClick={handleBack}>Back</LayoutButton>

              <LayoutButton variant="contained" color="info" type="submit" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : "Save"}
              </LayoutButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

LoanDetails.propTypes = {
  savingInputs: PropTypes.bool.isRequired,
  handleSaveInputs: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  detailsData: PropTypes.object.isRequired,
  selectedLoan: PropTypes.object.isRequired,
};

export default LoanDetails;
