import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { setLocalStorage, getFromLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";
import { showNotification } from "./notification.store";

// Initial state
const initialState = {
  data: [],
  branches: [],
  loading: false,
  status: "",
  authenticated: false,
  user: {},
  errors: null,
  status_completion: null,
};

// Create async actions for profile and user-related tasks
export const profileEdit = createAsyncThunk("user/profile-edit", async (payload, thunkAPI) => {
  try {
    return await api.profileInfo(payload);
  } catch (e) {
    Notify({ status: 421, message: e });
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const createNextOfKin = createAsyncThunk("user/create-next-of-kin", async (payload, thunkAPI) => {
  try {
    return await api.nextOfKin(payload);
  } catch (e) {
    Notify({ status: 421, message: e });
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const uploadProfileDocs = createAsyncThunk("user/upload-profile-docs", async (payload, thunkAPI) => {
  try {
    console.log("Uploading profile docs", payload);
    return await api.uploadDocs(payload);
  } catch (e) {
    Notify({ status: 421, message: e });
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const createUser = createAsyncThunk("user/auth/signup", async (payload, thunkAPI) => {
  try {
    return await api.registerUser(payload);
  } catch (e) {
    Notify({ status: 421, message: e });
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const sendOtp = createAsyncThunk("user/code", async (payload, thunkAPI) => {
  try {
    return await api.sendCode(payload);
  } catch (e) {
    Notify({ status: 421, message: e });
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const verifyOtp = createAsyncThunk("user/verifyCode", async (payload, thunkAPI) => {
  try {
    return await api.verificationCode(payload);
  } catch (e) {
    Notify({ status: 421, message: e });
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const resetPassword = createAsyncThunk("user/reset-password", async (payload, thunkAPI) => {
  try {
    return await api.resetPassword(payload);
  } catch (e) {
    Notify({ status: 421, message: e });
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const loginUser = createAsyncThunk("user/auth/login", async (payload, thunkAPI) => {
  try {
    return await api.login(payload);
  } catch (e) {
    Notify({ status: 421, message: e });
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

// User slice
const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action?.payload?.user || {};
        state.status = "success";
        if (action?.payload?.success) {
          Notify({ status: 200, message: action?.payload?.message });
          setLocalStorage("user", action?.payload?.user);
          setLocalStorage("token", action?.payload?.access_token);
        } else {
          Notify({ status: 421, message: action?.payload?.errors });
        }
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.status = "error";
        state.errors = action?.payload?.errors;
        Notify({ status: 421, message: action?.payload?.errors });
      })

    
      .addCase(profileEdit.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "success";
        if (action?.payload?.success) {
          setLocalStorage("user", action?.payload?.data);
          setLocalStorage("status_completion", action?.payload?.status_completion);
          state.user = action?.payload?.data || {};

        } else {
          Notify({ status: 421, message: action?.payload?.errors });
        }
      })
      .addCase(profileEdit.pending, (state) => {
        state.loading = true;
      })
      .addCase(profileEdit.rejected, (state, action) => {
        state.loading = false;
        state.status = "error";
        state.errors = action?.payload?.errors;
        Notify({ status: 421, message: action?.payload?.errors });
      })

    
      .addCase(createNextOfKin.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          setLocalStorage("user", action?.payload?.data);
          setLocalStorage("status_completion", action?.payload?.status_completion);
          state.user = action?.payload?.data || {};
        } else {
          Notify({ status: 421, message: action?.payload?.message });
        }
      })
      .addCase(createNextOfKin.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNextOfKin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action?.payload?.errors;
        Notify({ status: 421, message: action?.payload?.errors });
      })

    
      .addCase(uploadProfileDocs.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          Notify({ status: 200, message: action?.payload?.message });
          state.user = action?.payload?.data || {};
          setLocalStorage("user", action?.payload?.data);
          setLocalStorage("status_completion", action?.payload?.status_completion);
        } else {
          Notify({ status: 421, message: action?.payload?.message });
        }
      })
      .addCase(uploadProfileDocs.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadProfileDocs.rejected, (state, action) => {
        state.loading = false;
        state.errors = action?.payload?.errors;
        Notify({ status: 421, message: action?.payload?.message });
      })

    
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.authenticated = true;
        if (action?.payload?.success) {
          state.user = action?.payload?.user;
          state.status_completion = action?.payload?.status_completion;
          setLocalStorage("user", action?.payload?.user);
          setLocalStorage("token", action?.payload?.access_token);
          Notify({ status: 200, message: action?.payload?.message });
        } else {
          Notify({ status: 421, message: action?.payload?.message });
        }
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.status = "error";
        state.errors = action?.payload?.errors;
        Notify({ status: 421, message: action?.payload?.message });
      })

    
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          Notify({ status: 200, message: action?.payload?.message });
        } else {
          Notify({ status: 421, message: action?.payload?.message });
        }
      })
      .addCase(sendOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.loading = false;
        Notify({ status: 421, message: action?.payload?.message });
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          Notify({ status: 200, message: action?.payload?.message });
        } else {
          Notify({ status: 421, message: action?.payload?.message });
        }
      })
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        Notify({ status: 421, message: action?.payload?.message });
      })

    
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          Notify({ status: 200, message: action?.payload?.message });
        } else {
          Notify({ status: 421, message: action?.payload?.message });
        }
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default usersSlice.reducer;
