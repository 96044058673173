import { forwardRef, useState } from "react";
import { AppBar, Card, Container, Grid, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Index from "../../../../widgets/Cards/InfoCards/LoanInputs";
import { apply } from "../../../../store/loan.store";
import Notification from "../../../../components/Alert/Notification";


// // /* const PlatformLoans = forwardRef((props, ref) => {
// //   const userData = useSelector((state) => state?.users?.user);
// //   const dispatch=useDispatch();
// //   const handleSaveInputs = (profileData) => {

// //     // console.log("on save inpus", profileData)
// //     dispatch(apply(profileData))
// //     //// Placeholder function for onSaveCompany
// //    /*  return new Promise((resolve, reject) => {
// //      // // Replace this with your actual API call to save profile data
// //       setTimeout(() => {
// //         console.log("Profile data saved:", profileData);
// //         resolve();
// //       }, 1000);
// //     }
// //   ); */
// //   };

// //   return (
// //     <Card>
// //       <Container sx={{ mt: 3, mb: 3 }} maxWidth="xl">
// //         <Grid container spacing={4}>
// //           {/* Profile card */}

         
// //           <Grid item xl={12} xs={12}>
// //             <Index userData={userData} onSaveInputs={handleSaveInputs} initialIndex={0} />
// //           </Grid>
// //         </Grid>
// //       </Container>
// //     </Card>
// //   );
// // });

// // export default PlatformLoans;
//  */

const PlatformLoans = forwardRef((props, ref) => {
  const userData = useSelector((state) => state?.users?.user);
  const dispatch = useDispatch();

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const handleSaveInputs = async (profileData) => {
    try {
      const response = await dispatch(apply(profileData)).unwrap();
      setNotification({
        open: true,
        message: response.message || "Loan application submitted successfully!",
        severity: "success",
      });
    } catch (error) {
      setNotification({
        open: true,
        message: error.message || "Something went wrong!",
        severity: "error",
      });
    }
  };

  return (
    <Card>
      <Container sx={{ mt: 3, mb: 3 }} maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xl={12} xs={12}>
            <Index userData={userData} onSaveInputs={handleSaveInputs} initialIndex={0} />
          </Grid>
        </Grid>
      </Container>

      {/* Reusable Notification Component */}
      <Notification
      
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={handleCloseNotification}
      />
    </Card>
  );
});

export default PlatformLoans;