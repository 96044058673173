import PropTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import CompanyHeader from "../../../../layouts/profile/components/Companies/index";
import LimitedLiabilityCompany from "./LimitedLiabilityCompny";
import BusinessCompany from "./BusinessCompany";
import { useState } from "react";
function ProfileBusinessCard({ userData, onSaveCompany }) {


  const [bsCompanyData, setBsCompanyData] = useState({
    user_id: userData.id,
    id_pin: "", // Matches form field
    bank_statements: "", // Matches form field
    certificate_of_registration: "", // Matches form field
    business_license: "", // Matches form field
    resolution_to_borrow: "", // Matches form field
    company_description: "", // Matches text input
    financial_statements: "", // Matches form field
  });
  

  const [savingCompany, setSavingCompany] = useState(false);
 



  const handleSaveCompany = (values) => {
    console.log("Saving company data:", values);  // Log the values being submitted
    const userId = userData.id; 
    setSavingCompany(true);

    const dataToSubmit = {
      ...values,
      user_id: userId,  // Add user_id
    };
  
    console.log("Saving company data with user_id:", dataToSubmit);  // Log the values being submitted
  
  
    onSaveCompany(dataToSubmit)  // Use Formik's values instead of companyData
      .then(() => {
        console.log("Company data saved successfully");  // Log success
        setSavingCompany(false);
      })
      .catch((error) => {
        console.error("Error saving company data:", error);  // Log error
        setSavingCompany(false);
      });
  };
  
  



  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (newValue) => {
    console.log(" tab value obtaines now", newValue);
    setTabValue(newValue);
  };

  const renderCompanyTabs = () => {
    switch (tabValue) {
      case 0:
        return (
          <LimitedLiabilityCompany
            handleSaveCompany={handleSaveCompany}
            savingCompany={savingCompany}
          />
        );
      case 1:
        return (
          <BusinessCompany
            companyData={bsCompanyData}
            handleSaveCompany={handleSaveCompany}
            savingCompany={savingCompany}
          />
        );
      case 2:
        return <></>;
      default:
        return null;
    }
  };
  return (
    <CardContent>
      <Grid container spacing={2}>
        {/* Conditionally render additional fields if holder_type is "company" */}
        <CompanyHeader
          handleTabChange={handleTabChange}
          tabValue={tabValue}
          tabsOrientation={"horizontal"}
          setTabsOrientation={() => {}}
        />
        {renderCompanyTabs()}
      </Grid>

      {/* You can add more conditions for additional steps if needed */}
    </CardContent>
  );
}

ProfileBusinessCard.propTypes = {
  userData: PropTypes.object.isRequired,
  onSaveCompany: PropTypes.func.isRequired,
};

export default ProfileBusinessCard;
