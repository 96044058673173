import propTypes from "prop-types";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import LoanDetails from "./LoanDetails";
import DetailStepper from "./DetailStepper";
import LayoutStepper from "../../../../components/LayoutStepper";
import LayoutBox from "../../../../components/LayoutBox";
import LoansInput from "../../../../assets/data/LoanInputs.json";
import LayoutTypography from "../../../../components/LayoutTypography";

const Index = ({ userData, onSaveInputs, initialIndex }) => {
  // ✅ Default initialIndex
  const [savingInputs, setSavingInputs] = useState(false);
  const [formData, setFormData] = useState({}); // ✅ Define formData
  const stepperRef = useRef();
  const steps = ["Loan Details", "Loan Documents"];
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loan_id = parseInt(queryParams.get("loan_id")) || 1;

  const selectedLoan = LoansInput.find((loan) => loan.id === loan_id);

  useEffect(() => {
    console.log("Loans mounted with initialStep:", initialIndex);
    setActiveStep(initialIndex);
  }, [initialIndex]);

  const markStepAsCompleted = (step) => {
    setCompleted((prev) => ({ ...prev, [step]: true }));
  };

  // ✅ Handle clicking on steps with validation
  const handleStep = useCallback(
    (index) => {
      console.log("Step clicked:", index);

      // Prevent skipping steps unless the previous one is completed
      if (index > activeStep && !completed[activeStep]) {
        console.log("Complete the current step before proceeding.");
        return;
      }

      if (index !== activeStep) {
        setActiveStep(index);
      }
    },
    [activeStep, completed]
  );

  const handleSaveInputs = (data) => {
    setSavingInputs(true);
    onSaveInputs(data);
    setSavingInputs(false);
  };

  const renderLoanStep = useMemo(() => {
    if (activeStep === 0) {
      return (
        <DetailStepper
          setActiveStep={setActiveStep}
          setFormData={setFormData}
          selectedLoan={selectedLoan}
          userData={userData}
          markStepAsCompleted={() => markStepAsCompleted(0)} // Mark step as completed
        />
      );
    }

    if (activeStep === 1) {
      return (
        <LoanDetails
        handleSaveInputs={(data) => {
          handleSaveInputs(data); // ✅ Pass the correct data
          markStepAsCompleted(1);
        }}
          savingInputs={savingInputs}
          setActiveStep={setActiveStep}
          detailsData={formData}
          selectedLoan={selectedLoan}
        />
      );
    }

    return null;
  }, [activeStep, savingInputs]);

  // const handleStep = useCallback(
  //   (index) => {
  //     console.log("index step", index);
  //     if (index !== activeStep) {
  //       setActiveStep(index);
  //     }
  //   },
  //   [activeStep]
  // );

  return (
    <CardContent>
      <Grid item xs={12}>
        <LayoutTypography
          variant="h2"
          fontWeight="medium"
          textTransform="capitalize"
          textAlign="center"
        >
          {selectedLoan?.title}
        </LayoutTypography>
      </Grid>
      <LayoutStepper
        id={"stepper1"}
        steps={steps}
        ref={stepperRef}
        completed={completed}
        activeStep={activeStep}
        handleStep={handleStep}
      />
      <LayoutBox mt={5} mb={3}>
        {renderLoanStep}
      </LayoutBox>
    </CardContent>
  );
};

Index.propTypes = {
  onSaveInputs: propTypes.func.isRequired,
  initialIndex: propTypes.number, // ✅ Define initialIndex in propTypes
  userData: propTypes.object, // ✅ Define initialIndex in propTypes
};

export default Index;
