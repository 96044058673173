import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { setLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";
import { error } from "jquery";

const initialState = {
  data: [],
  loading: false,
  my_loans: [],
  repayments: [],
  payment_schedule: [],
  repayment_history: [],
  branches: [],
  error: null,
};

export const calculateLoan = createAsyncThunk("loans/calcuate", async (payload, thunkAPI) => {
  try {
    return await api.calculate(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const loanCount = createAsyncThunk("loans/count", async (_, thunkAPI) => {
  try {
    return await api.getLoanCount();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const myLoans = createAsyncThunk("loans/myLoans", async (_, thunkAPI) => {
  try {
    return await api.myLoans();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const loanDetails = createAsyncThunk("loans/loanDetails", async (payload, thunkAPI) => {
  try {
    return await api.loanDetails(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const apply = createAsyncThunk("loans/apply", async (payload, thunkAPI) => {
  try {
    return await api.apply(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const pay = createAsyncThunk("loans/loan-details", async (payload, thunkAPI) => {
  try {
    return await api.pay(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const newLead = createAsyncThunk("loans/newLead", async (payload, thunkAPI) => {
  try {
    return await api.newLead(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const uploadDocuments = createAsyncThunk(
  "loans/upload-documents",
  async (payload, thunkAPI) => {
    try {
      return await api.uploadDocuments(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const loansSlice = createSlice({
  name: "loans",
  initialState,
  extraReducers: (builder) => {
    //Loan calculation
    builder
      .addCase(calculateLoan.fulfilled, (state, action) => {
        //set the calculation response to the store
        state.loading = false;
        state.application_results = action?.results;
      })

      .addCase(calculateLoan.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(calculateLoan.rejected, (state, action) => {
        state.loading = false;
      })

      //Uploading Documents
      .addCase(uploadDocuments.fulfilled, (state, action) => {
        //set the calculation response to the store
        state.loading = false;
        state.application_results = action?.results;
      })

      .addCase(uploadDocuments.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(uploadDocuments.rejected, (state, action) => {
        state.loading = false;
      })

      //New lead
      .addCase(newLead.fulfilled, (state, action) => {
        //set the calculation response to the store
        state.loading = false;
        if (action?.payload?.success === true) {
          //   state.my_loans = action?.payload?.data;
          // this should have its own button
        } else if (action?.payload?.success === false) {
          Notify({ status: 421, message: action?.payload?.error });
        }
      })

      .addCase(newLead.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(newLead.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(apply.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(apply.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success === true) {
          // state.my_loans=action?.payload?.message
          console.log("status message", action.payload.message);
        } else if (action?.payload?.success == false) {
        }
      })
      .addCase(apply.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(myLoans.fulfilled, (state, action) => {
        // Set the calculation response to the store
        state.loading = false;

        if (action?.payload?.success === true) {
          // Store all the loans instead of just the first loan
          state.my_loans = action?.payload?.data.map((loan) => loan.data); // All loan data
          state.repayments = action?.payload?.data.map((loan) => loan.repayment); // All repayment data
          state.payment_schedule = action?.payload?.data.map((loan) => loan.paymentSchedule); // All payment schedule data
          state.repayment_history = action?.payload?.data.map((loan) => loan.repaymentHistory); // All repayment history data

          // Optionally log the full data for debugging
          console.log("My loans are:", action?.payload?.data);
        } else if (action?.payload?.success === false) {
          Notify({ status: 421, message: action?.payload?.error });
        }
      })

      .addCase(myLoans.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(myLoans.rejected, (state, action) => {
        state.loading = false;
      })

      //Loan Details
      .addCase(loanDetails.fulfilled, (state, action) => {
        //set the calculation response to the store
        state.loading = false;
        state.application_results = action?.results;
      })

      .addCase(loanDetails.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(loanDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error;
      })

      //loan count
      .addCase(loanCount.fulfilled, (state, action) => {
        //set the calculation response to the store
        state.loading = false;
        state.application_results = action?.results;
      })

      .addCase(loanCount.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(loanCount.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default loansSlice.reducer;
