import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LayoutInput from "../../../../components/LayoutInput";
import LayoutButton from "../../../../components/LayoutButton";

const validationSchema = Yup.object({
  amount_requested: Yup.number()
    .required("Loan Amount is required")
    .min(50000, "Loan amount must be at least 50,000")
    .max(50000000, "Loan amount must be less than 50,000,000"),
  period_in_months: Yup.number()
    .required("Loan Period is required")
    .min(1, "Loan period must be at least 1 month")
    .max(24, "Loan period must be less than 24 months"),
  applicant_type: Yup.string().required("Application Type is required"),
});

const DetailStepper = ({
  setActiveStep,
  setFormData,
  selectedLoan,
  markStepAsCompleted,
  userData,
}) => {
  const handleSubmit = (values) => {
    setFormData(values);
    markStepAsCompleted(0); // ✅ Mark step as completed
    setActiveStep(1);
  };

  return (
    <Formik
      initialValues={{
        loan_product_id: selectedLoan?.loan_product_id,
        amount_requested: "",
        loan_purpose: "",
        kra_pin: userData?.customer_profile?.kra_pin_id,
        period_in_months: "",
        applicant_type: "INDIVIDUAL", // ✅ Default value
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={LayoutInput}
                name="amount_requested"
                placeholder="Loan Amount"
                error={touched.amount_requested && !!errors.amount_requested}
                helperText={touched.amount_requested && errors.amount_requested}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={LayoutInput}
                name="period_in_months"
                placeholder="Loan Period"
                error={touched.period_in_months && !!errors.period_in_months}
                helperText={touched.period_in_months && errors.period_in_months}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={LayoutInput}
                multiline
                rows={4}
                name="loan_purpose"
                placeholder="Loan Purpose"
                error={touched.loan_purpose && !!errors.loan_purpose}
                helperText={touched.loan_purpose && errors.loan_purpose}
              />
            </Grid>

            {/* Dropdown for Application Type */}
            <Grid item xs={12}>
              <LayoutInput
                id="applicant_type"
                name="applicant_type"
                select
                label="Application Type"
                value={values.applicant_type}
                selectedValue={values.applicant_type}
                handleChange={(e) => setFieldValue("applicant_type", e.target.value)}
                options={[
                  { value: "INDIVIDUAL", label: "Individual" },
                  { value: "BUSINESS", label: "Business" },
                  { value: "LLC", label: "LIMITED LIABILITY COMPANY" },
                ]}
                error={touched.applicant_type && !!errors.applicant_type}
                helperText={touched.applicant_type && errors.applicant_type}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <LayoutButton type="submit" variant="contained" color="info">
              Next
            </LayoutButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

DetailStepper.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  selectedLoan: PropTypes.object.isRequired,
  markStepAsCompleted: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired, // ✅ Added prop validation
};

export default DetailStepper;
