import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createUser, sendOtp } from "../../store/users.store";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons for password visibility toggle

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const loading = useSelector((state) => state.users.loading);
  const [phoneNo, setPhoneNo] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);

  const containerStyle = { display: "flex", justifyContent: "center", alignItems: "center" };
  const formStyle = { padding: "20px", background: "white", textAlign: "left" };
  const formGroupStyle = { marginBottom: "15px", marginTop: "0", textAlign: "left" };

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleSendOtp = (values) => {
    setPhoneNo(values.phone_no);
    dispatch(sendOtp({ phone_no: values.phone_no })).then((response) => {
      if (response?.payload?.success) {
        setIsOtpSent(true);
      }
    });
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    if (!values.otp_code) {
      alert("Please enter the OTP before submitting");
      setSubmitting(false);
      return;
    }
    const { confirmPassword, ...signupData } = values; // Omit confirmPassword from the signup payload
    dispatch(createUser(signupData)).then((result) => {
      if (result?.payload?.success === true) {
        navigate("/auth");
      }
      setSubmitting(false);
    });
  };

  // Yup validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    surname: Yup.string().required("Surname is required"),
    middle_name: Yup.string().required("Middle name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone_no: Yup.string().required("Phone number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    otp_code: Yup.string().when("isOtpSent", {
      is: true,
      then: Yup.string().required("OTP is required"),
    }),
    id_no: Yup.string().required("ID number is required"),
    gender: Yup.string().required("Gender is required"),
  });

  return (
    <div className="item item-13 container-fluid">
      <div className="img-fill row">
        <nav className="navbar navbar-expand-lg" style={{ position: "relative", zIndex: "1000" }}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                src="./assets/images/logo.png"
                alt="quick sava logo"
                style={{ width: "6.5cm" }}
              />
            </Link>
          </div>
        </nav>

        <div className="row">
          <div className="col-md-6 col-sm-12 service_heading">
            <div style={containerStyle}>
              <div className="auth service_heading service-size" style={formStyle}>
                <h1>
                  <strong style={{ fontFamily: "trueno" }}>Sign Up</strong>
                </h1>
                <p className="text-dark">
                  Already have an account?{" "}
                  <Link to="/auth" style={{ color: "#ff8b00" }}>
                    Sign in
                  </Link>
                </p>

                {/* Registration Form */}
                <Formik
                  initialValues={{
                    name: "",
                    surname: "",
                    email: "",
                    phone_no: "+254",
                    middle_name: "",
                    password: "",
                    confirmPassword: "",
                    otp_code: "",
                    id_no: "", // Add initial value for id_no
                    gender: "", // Add initial value for gender
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (isOtpSent) {
                      handleFormSubmit(values, { setSubmitting });
                    } else {
                      handleSendOtp(values);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ values, setFieldValue, isSubmitting }) => (
                    <Form>
                      {/* Name and Surname */}
                      <div className="row">
                        <div style={formGroupStyle} className="col-md-6">
                          <Field
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            style={{ borderRadius: "55px" }}
                          />
                          <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={formGroupStyle} className="col-md-6">
                          <Field
                            type="text"
                            name="surname"
                            className="form-control"
                            placeholder="Surname"
                            style={{ borderRadius: "55px" }}
                          />
                          <ErrorMessage name="surname" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="row">
                        <div style={formGroupStyle} className="col-md-6">
                          <Field
                            type="text"
                            name="middle_name"
                            className="form-control"
                            placeholder="Middle Name"
                            style={{ borderRadius: "55px" }}
                          />
                          <ErrorMessage
                            name="middle_name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* Email */}
                        <div style={formGroupStyle} className="col-md-6">
                          <Field
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            style={{ borderRadius: "55px" }}
                          />
                          <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                      </div>

                      {/* Phone number and OTP Button */}
                      <div style={formGroupStyle} className="d-flex align-items-center">
                        <Field
                          type="tel"
                          name="phone_no"
                          className="form-control"
                          placeholder="+254"
                          style={{ borderRadius: "55px", marginRight: "10px" }}
                        />
                        <button
                          type="button"
                          className="btn text-light"
                          disabled={values.phone_no.length < 5} // Disable if phone number length is less than 4
                          onClick={() => handleSendOtp(values)}
                          style={{ borderRadius: "55px", backgroundColor: "#ff8b00" }}
                        >
                          Send OTP
                        </button>
                        <ErrorMessage name="phone_no" component="div" className="text-danger" />
                      </div>

                      {/* Password and Confirm Password */}
                      <div style={formGroupStyle}>
                        <div className="input-group" style={{ position: "relative" }}>
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            style={{
                              borderRadius: "55px",
                              paddingRight: "40px", // Add padding for the icon to not overlap with the text
                            }}
                          />
                          <span
                            className="input-group-text"
                            onClick={toggleShowPassword}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        <ErrorMessage name="password" component="div" className="text-danger" />
                      </div>

                      <div style={formGroupStyle}>
                        <div className="input-group" style={{ position: "relative" }}>
                          <Field
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Confirm Password"
                            style={{
                              borderRadius: "55px",
                              paddingRight: "40px", // Add padding for the icon to not overlap with the text
                            }}
                          />
                          <span
                            className="input-group-text"
                            onClick={toggleShowConfirmPassword}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          >
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* ID Number */}
                      <div style={formGroupStyle}>
                        <Field
                          type="text"
                          name="id_no"
                          className="form-control"
                          placeholder="ID Number"
                          style={{ borderRadius: "55px" }}
                        />
                        <ErrorMessage name="id_no" component="div" className="text-danger" />
                      </div>

                      {/* Gender Selection */}
                      <div style={formGroupStyle}>
                        <Field
                          as="select"
                          name="gender"
                          className="form-control"
                          style={{ borderRadius: "55px" }}
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </Field>
                        <ErrorMessage name="gender" component="div" className="text-danger" />
                      </div>

                      {/* OTP Code */}
                      {isOtpSent && (
                        <div style={formGroupStyle}>
                          <Field
                            type="text"
                            name="otp_code"
                            className="form-control"
                            placeholder="Enter OTP"
                            style={{ borderRadius: "55px" }}
                          />
                          <ErrorMessage name="otp_code" component="div" className="text-danger" />
                        </div>
                      )}

                      {/* Submit Button */}
                      <div className="d-flex justify-content-center" style={{ marginTop: "10px" }}>
                        <button
                          type="submit"
                          className="btn w-100 text-light"
                          disabled={
                            isSubmitting ||
                            (!isOtpSent && !values.otp_code && values.otp_code.length < 2)
                          }
                          style={{
                            backgroundColor: "#ff8b00",
                            borderRadius: "55px",
                            fontSize: ".6cm",
                          }}
                        >
                          {loading ? "Please Wait..." : "Complete Signup"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Register;
