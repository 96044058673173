import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { resetPassword } from "../../store/users.store";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const loading = useSelector((state) => state.users.loading);

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh',
    marginBottom: "3cm",
  };

  const formStyle = {
    width: "350px",
    padding: "20px",
    background: "white",
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    textAlign: "left",
  };

  const formGroupStyle = {
    marginBottom: "20px",
    textAlign: "left",
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }


  //yup validation
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone_no: Yup.string().required("phone number is required"),
    password: Yup.string().required("password is required").min(8, "password must be atleast 8 characters"),
    confirm_password: Yup.string().required("confirm password is required").oneOf([Yup.ref("password"), null], "passwords must match"),
    otp_code: Yup.string().when("isOtpSent", {
      is: true,
      then: Yup.string().required("OTP is required"),
    }),
  })

  const handleFormSubmit = (values, { setSubmitting }) => {
    console.log("The data from the login form are : ", values);

    dispatch(resetPassword(values)).then((result) => {
      console.log("The result from the store action is ", result);
      if (result?.payload?.success === true) {
        // navigate("/auth")
      }
    });
    setSubmitting(false);
  };

  return (
    <div className="item item-13 container-fluid">
      <div className="img-fill row">
        <nav className="navbar navbar-expand-lg" style={{ position: "relative", zIndex: "1000" }}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src="./assets/images/logo.png" alt="quick sava logo" style={{ width: "8cm" }} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="col-md-6 col-sm-12 service_heading service-size">
          <div style={containerStyle}>
            <div className="auth service_heading" style={formStyle}>
              <h3 className="mb-3">
                <strong style={{ fontFamily: "trueno" }}>Reset Password</strong>
              </h3>

              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  phone_no: "",
                }}
                onSubmit={handleFormSubmit}
                validationSchema={validationSchema}
              >
                <Form>
                  <div style={formGroupStyle}>
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="email"
                      style={{ borderRadius: "55px" }}
                      required
                    />
                  </div>

                  <div style={formGroupStyle}>
                    <Field
                      type="text"
                      name="phone_no"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="+254"
                      style={{ borderRadius: "55px" }}
                      required
                    />
                  </div>

                 {/* Password and Confirm Password */}
                 <div style={formGroupStyle}>
                        <div className="input-group" style={{ position: "relative" }}>
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            style={{
                              borderRadius: "55px",
                              paddingRight: "40px", // Add padding for the icon to not overlap with the text
                            }}
                          />
                          <span
                            className="input-group-text"
                            onClick={toggleShowPassword}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        <ErrorMessage name="password" component="div" className="text-danger" />
                      </div>

                      <div style={formGroupStyle}>
                        <div className="input-group" style={{ position: "relative" }}>
                          <Field
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirm_password"
                            className="form-control"
                            placeholder="Confirm Password"
                            style={{
                              borderRadius: "55px",
                              paddingRight: "40px", // Add padding for the icon to not overlap with the text
                            }}
                          />
                          <span
                            className="input-group-text"
                            onClick={toggleShowConfirmPassword}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          >
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        <ErrorMessage
                          name="confirm_password"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                  <div style={formGroupStyle}>
                    <Field
                      type="text"
                      name="otp"
                      className="form-control"
                      id="exampleInputPassword"
                      placeholder="Otp"
                      style={{ borderRadius: "55px" }}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn  w-100 text-light"
                    disabled={loading}
                    style={{ borderRadius: "5px", backgroundColor: "#ff8b00", fontSize: ".6cm" }}
                  >
                    <strong>Reset Password</strong>
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer style={{ zIndex: "1", position: "absolute", top: "100px" }} />
    </div>
  );
};

export default ForgotPassword;
